@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap);

body {
  padding: 0;
  margin: 0;
  font-family: Oswald;
}

.op-app {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 100px auto;
  grid-template-areas:
    'header'
    'body';
}

.op-app .op-header {
  grid-area: header;
}

.op-app .op-body {
  grid-area: body;
}

.op-app .op-body .op-content-slider {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.op-app .op-body .op-content-slider .op-slider {
  display: flex;
  width: 400%;
  height: 100%;
}

.op-app .op-body .op-content-slider .op-slider .op-slider__section {
  width: 100%;
}

.op-app .op-body .op-content-slider .slider_imagen {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
