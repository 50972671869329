.op-bloque-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  padding: 0 100px;
}

.op-bloque-image .op-imagen {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  height: 300px;
  width: 100%;
  flex-basis: 50%;
  order: 1;
  animation: imagen-loader 1000ms ease-in;
}

.op-bloque-image.cambiado .op-imagen {
  order: 2;
}

.op-bloque-image .texto {
  flex-basis: 50%;
  width: 100%;
  order: 2;
}

.op-bloque-image .texto span {
  text-align: justify;
  text-justify: inter-word;
}

.op-bloque-image.cambiado .texto {
  order: 1;
}

@keyframes imagen-loader {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

@media (max-width: 858px) {
  .op-bloque-image {
    padding: 0 50px;
    height: auto;
  }
}

@media (max-width: 576px) {
  .op-bloque-image {
    padding: 0 30px;
    display: grid;
  }

  .op-bloque-image.cambiado .texto,
  .op-bloque-image .texto {
    order: 1;
  }

  .op-bloque-image.cambiado .op-imagen,
  .op-bloque-image .op-imagen {
    order: 2;
    height: 250px;
  }
}
